.api-key-management {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header,
  .footer {
    flex: 0 0 auto;
  }

  .header {
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  }

  .contents {
    flex: 1 0 auto;
    padding: 24px 40px;

    .wrapper {
      width: 100%;

      .package-title {
        color: #383838;
        font-family: Roboto;
        font-size: 13px;
        line-height: 1.38;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .package-names-list {
        margin-bottom: 30px;

        .package-name {
          color: #293033;
          font-family: Roboto;
          font-size: 18px;
          line-height: 1.11;
          margin: 10px 0;
        }
      }

      .caption {
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.71;
        color: #525252;
        margin-bottom: 30px;
      }

      .generate-key-button {
        width: 153px;
        margin: 0 0 20px 0;
      }

      .separator {
        width: 100%;
        height: 1px;
        background-color: #e3e3e3;
        margin-bottom: 20px;
      }

      .history-title {
        font-family: Roboto;
        font-size: 18px;
        line-height: 1.33;
        color: #7a7a7a;
        margin-bottom: 20px;
      }
    }
  }
}