.error-table-row {
  width: 100%;
  height: 56px;
  display: flex;

  .error-message {
    display: flex;
    align-items: center;
    width: 100%;
    color: #525252;
    font-family: Roboto;
    font-size: 13px;
    line-height: 1.85;
    margin-left: 16px;

    .bold-text {
      font-weight: bold;
    }
  }
}