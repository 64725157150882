.side-nav-item {
  width: 80px;
  height: 80px;
  min-height: 80px;
  background-image: url("../../../assets/imgs/icons.svg");
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.active {
    background-color: #0e8fc9;
  }

  &.resources {
    position: fixed;
    bottom: 80px;
    z-index: 10;
  }
}
