.add-edit-user > .modal-content > .modal-body {
  .error-banner {
    .message {
      margin-top: 0;
    }
  }

  .row-title {
    color: #525252;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    padding-bottom: 12px;
    cursor: default;
  }

  .row-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 24px;

    &:last-child {
      align-items: flex-start;
      justify-content: flex-start;
      width: 600px;
      padding-bottom: 60px;

      .row-title {
        padding-bottom: 0;
      }
    }

    .input-textbox-container {
      width: 400px;
    }

    .input-checkbox-group {
      padding-right: 80px;
      padding-bottom: 24px;

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .input-checkbox-container {
        width: fit-content;
      }

      .info-icon {
        width: 18px;
        height: 18px;
        background-image: url("../../../../assets/imgs/icons.svg");
        background-position: -23px -246px;
        background-repeat: no-repeat;
      }

      .cybcube-tooltip {
        .cybcube-tooltip-arrow {
          left: -16px;
          top: 10px;
        }

        .cybcube-tooltip-info {
          left: -120px;
          top: 20px;
        }
      }
    }
  }
}
