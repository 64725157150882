@import "./styleConstants";

html,
body {
  height: 100%;
  font-family: Roboto;
  padding: 0;
  margin: 0;
}

#shellContainer {
  width: 100%;
  height: 100vh;

  .shell {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;

    .main-view__container {
      width: 100%;
      height: 100%;
      background: #f2f2f2;
      overflow: auto;

      .main-view {
        height: 100%;
        min-width: 1400px;
        display: flex;
        flex-direction: column;
      }

      .footer {
        height: 30px;
        background-color: transparent;
        color: #777777;
        font-size: 12px;
        padding-bottom: 15px;
        border: none;
        position: relative;
      }
    }
  }
}
