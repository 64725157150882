.side-nav-user {
  width: 80px;
  height: 80px;

  .toggle-button {
    width: 80px;
    height: 80px;
    background-image: url("../../../assets/imgs/icons.svg");
    background-position: -195px -374px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;

    &:hover {
      background-position: -195px -374px;
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.btn-secondary:not(:disabled):not(.disabled):active {
      &:focus {
        box-shadow: none;
      }
    }

    &.open {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .popup-menu {
    bottom: 20px;
    width: 355px;
    height: fit-content;
    background-color: #ffffff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    border: none;
    margin-top: auto;
    margin-left: 20px;
    padding: 0;

    .app-info,
    .user-info {
      font-family: Roboto;
      font-size: 14px;
      line-height: 2.86;
      color: #5c5c5c;
      padding: 8px 16px;

      div:first-child {
        color: #383838;
        font-weight: 500;
        padding-left: 0;
      }

      div {
        padding-left: 15px;
      }
    }

    .user-info {
      border-bottom: 1px solid #eee;
    }

    .logout-button {
      text-align: left;

      &:active,
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
}
