.user-admin-table.table-container {
  min-width: 1300px;

  .table-headers > .table-header {
    &:first-child {
      flex: 1 0 230px;
      padding-left: 60px;
    }

    &:nth-child(2) {
      flex: 1 0 240px;
    }

    &:nth-child(3) {
      flex: 0 0 160px;
    }

    &:nth-child(4) {
      flex: 0 0 160px;
    }

    &:nth-child(5) {
      flex: 0 0 185px;
    }

    &:nth-of-type(6),
    &:nth-of-type(7) {
      flex: 1 0 150px;
    }
    
    &:nth-of-type(7) {
      overflow: visible;
    }

    &:last-child {
      flex: 0 1 25px;
      padding: 0;
    }

    .disabled-date-header {
      display: flex;
      align-items: center;

      .info-icon {
        width: 18px;
        height: 18px;
        background-image: url("../../../assets/imgs/icons.svg");
        background-position: -23px -246px;
        background-repeat: no-repeat;
        margin-left: 8px;
        cursor: pointer;
      }

      .cybcube-tooltip {
        left: -14px;
        bottom: -14px;

        .cybcube-tooltip-info {
          left: -200px;
          width: auto;
        }
      }
    }
  }

  .table-body {
    min-height: 554px;

    .load-animation-wrapper {
      :nth-child(1) {
        flex: 0 0 214px;
      }

      :nth-child(2) {
        flex: 0 0 239px;
      }

      :nth-child(3) {
        flex: 0 0 124px;
      }

      :nth-child(4) {
        flex: 0 0 154px;
      }

      :nth-child(5) {
        flex: 1;
      }

      :nth-child(6) {
        display: none;
      }
    }
  }
}