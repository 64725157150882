.revoke-confirmation-modal {
  .modal-content {
    .modal-subheader {
      height: 56px;
      background-color: #f0ad11;
      color: #293033;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
      padding: 16px 24px;
      cursor: default;

      .warning-icon {
        width: 24px;
        height: 24px;
        background-image: url("../../../assets/imgs/icons.svg");
        background-position: -23px -751px;
        background-repeat: no-repeat;
        margin-right: 5px;
        float: left;
      }
    }

    .modal-body {
      .message {
        color: #525252;
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.71;
      }
    }

    .modal-footer {
      .primary {
        width: 90px;
      }

      .secondary {
        width: 75px;
        margin-right: 16px;
      }
    }
  }
}