@keyframes table-loader {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.table-container {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.95;
  overflow: hidden;

  .table-headers {
    width: 100%;
    height: 48px;
    background-color: #293033;
    display: flex;
    flex-wrap: nowrap;

    .table-header {
      position: relative;
      display: inline-block;
      width: 150px;
      margin: 0px;
      font-family: Roboto;
      font-size: 14px;
      color: #ffffff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 14px 24px;
      flex-grow: 1;

      &.sortable {
        cursor: pointer;
      }

      .sort-icons {
        display: none;

        &.active {
          position: absolute;
          right: 0px;
          top: 14px;
          display: flex;
          flex-direction: column;
          height: 20px;
          font-size: 10px;
          margin: 0px 7px;

          .fa-caret-up,
          .fa-caret-down {
            font-size: 14px;
          }

          .fa-caret-up {
            margin-bottom: -5px;
          }
        }

        &.asc {
          .fa-caret-up {
            color: #aaa;
          }
        }

        &.dsc {
          .fa-caret-down {
            color: #aaa;
          }
        }
      }
    }
  }

  .table-body {
    overflow: auto;

    .table-row {
      width: 100%;
      height: 56px;
      display: flex;
      flex-wrap: nowrap;
      background-color: #ffffff;

      &:nth-child(odd) {
        background-color: #fafafa;
      }

      &:hover {
        background-color: #ebebeb;
      }

      .table-element {
        position: relative;
        display: inline-block;
        margin: 0px;
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.71;
        color: #525252;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 16px 24px;
        flex-grow: 1;
      }
    }

    .load-animation-wrapper {
      display: flex;
      flex-wrap: nowrap;
      padding: 23px 10px;

      &:nth-child(odd) {
        background-color: #fafafa;
      }

      .load-animation {
        height: 8px;
        background: linear-gradient(to right, #ebebeb 20%, #d9d9d9 50%, #ebebeb 80%);
        background-size: 200%;
        animation: table-loader 1.5s infinite;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        border-radius: 3px;
        flex-grow: 1;
        margin: 0 18px;
      }
    }
  }
}
