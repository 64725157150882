@import "../styleConstants";


@import "../../styleConstants.scss";

.side-nav {
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100%;
  background-color: #373b40;

  .side-nav-options {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    -ms-overflow-style: none; // IE 10+
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
  }

  .side-nav-user {
    margin-top: auto; // Push nav-item to bottom of nav-bar
    border-bottom: none;
  }
}

