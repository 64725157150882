.federation {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header,
  .footer {
    flex: 0 0 auto;
  }

  .main-header {
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  }

  .contents {
    flex: 1 0 auto;
    padding: 24px 40px;

    .wrapper {
      max-width: 1000px;

      .title {
        color: #585959;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 20px;
      }

      .caption {
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.71;
        color: #737373;
        margin-bottom: 24px;
      }

      .section {
        display: flex;
        flex-direction: row;
        align-items: center;

        .copy-button {
          margin-bottom: 6px;
          margin-left: 16px;
          padding: 0;

          &:active,
          &:hover,
          &:focus {
            background-color: transparent;
          }
        }
      }

      .auth-only-checkbox {
        margin-bottom: 50px;
      }

      .entity-textbox,
      .identifiers-textbox,
      .metadata-textbox,
      .signon-textbox {
        width: 435px;

        .input-textbox {
          border: solid 1px #dee0e0;
        }
      }

      .upload-button {
        width: 146px;
        margin: 0;
      }
    }
  }
}
