.user-admin-table.table-container > .table-body > .table-row {
  height: 64px;

  .table-element {
    position: relative;
    display: inline-block;
    color: #525252;
    font-size: 14px;
    line-height: 1.71;
    margin: 0;
    padding: 20px 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;

    &:nth-of-type(1) {
      flex: 1 0 230px;
      padding-left: 60px;

      .input-checkbox-container {
        margin: 0px;
        padding: 0px;

        .input-checkmark {
          top: 2px;
          left: -35px;
        }
      }
    }

    &:nth-of-type(2) {
      flex: 1 0 240px;
    }

    &:nth-of-type(3) {
      display: flex;
      align-items: center;
      flex: 0 0 160px;
      padding: 10px 24px;
    }

    &:nth-of-type(4) {
      flex: 0 0 160px;
    }

    &:nth-of-type(5) {
      flex: 0 0 185px;
    }

    &:nth-of-type(6),
    &:nth-of-type(7) {
      flex: 1 0 150px;
    }

    .clickable-text {
      color: #0b74a3;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .hoverable-text {
      color: #0b74a3;
      margin-left: 6px;
      cursor: default;
    }

    .tooltip-contents {
      span {
        display: block;
        margin-top: 15px;

        &:first-child {
          margin-top: 0px;
        }
      }
    }
  }

  .user-options {
    display: flex;
    justify-content: flex-end;
    flex: 0 1 25px;

    .c2-dropdown-options-wrapper {
      .c2-dropdown-options {
        width: auto;

        .c2-dropdown-menu-item {
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
