.api-key-display {
  width: 100%;
  margin-bottom: 30px;

  .attention-banner {
    display: none;
    width: 100%;
    height: 40px;
    color: #3c474d;
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.71;
    padding: 10px 20px;

    &.expired,
    &.revoked {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #bd2520;
      color: #fff;

      .expired-icon {
        width: 20px;
        height: 20px;
        background-image: url("../../../assets/imgs/icons.svg");
        background-position: -722px -153px;
        background-repeat: no-repeat;
        margin-right: 10px;
      }
    }

    &.expiring {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #f0ad11;
      color: #293033;

      .expiring-icon {
        width: 20px;
        height: 20px;
        background-image: url("../../../assets/imgs/icons.svg");
        background-position: -24px -750px;
        background-repeat: no-repeat;
        margin-right: 10px;
      }
    }
  }

  .contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 88px;
    background-color: #fff;
    padding: 24px;

    .key-details {
      display: flex;
      flex-direction: row;
      align-items: center;

      .column {
        &:first-child {
          margin-right: 10px;

          .title {
            margin-right: 350px;
          }
        }

        &:nth-child(2) {
          .title {
            margin-right: 100px;
          }

          .text {
            display: flex;
            align-items: center;

            .status-icon {
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              margin-right: 5px;

              &.active {
                background-color: #5ba31c;
              }

              &.expiring {
                background-color: #f0ad11;
              }

              &.expired,
              &.revoked {
                background-color: #bd2520;
              }

              &.unavailable {
                background-color: #ebebeb;
              }
            }
          }
        }

        .title {
          font-family: Roboto;
          font-size: 13px;
          line-height: 1.54;
          letter-spacing: 0.4px;
          color: #525252;
        }

        .text {
          height: 23px;
          font-family: Roboto;
          font-size: 14px;
          line-height: 1.71;
          color: #383838;
        }
      }
    }

    .key-actions {
      .key-copy-button {
        width: 94px;
        margin-right: 16px;
      }

      .key-revoke-button {
        width: 109px;
      }
    }
  }
}