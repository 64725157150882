.upload-metadata-modal {
  .modal-subheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    background-color: #f29b05;
    color: #3f4040;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 24px;
    cursor: default;

    .warning-icon {
      width: 22px;
      height: 18px;
      background-image: url("../../../assets/imgs/icons.svg");
      background-position: -23px -751px;
      background-repeat: no-repeat;
      margin-right: 10px;
      float: left;
    }

    .warning-text {
      font-family: Roboto;
      font-size: 14px;
      color: #404040;
    }
  }

  .section {
    display: flex;
    flex-direction: row;
    align-items: center;

    .identifiers-textbox,
    .metadata-textbox {
      width: 344px;
    }

    .or {
      color: #727273;
      font-family: Roboto;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.54;
      letter-spacing: 0.4px;
      margin: 0 12px;
    }

    .file-upload {
      width: 118px;
      border-radius: 2px;
      border: solid 1px #d4d6d6;
      background-color: #f5f7f7;
      color: #585959;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 6px;
    }

    .file-name {
      color: #727273;
      font-family: Roboto;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.54;
      letter-spacing: 0.4px;
      margin-left: 12px;
    }
  }
}

.modal.fade {
  .modal-small {
    &.user-modal-sm {
      &.metadata-confirm {
        width: 370px;
        max-width: unset;
        height: 380px;
        max-height: 380px;
      }
    }
  }
}