.user-inputs-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 20px 0;

  .table-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;

    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;

    .search-user-input {
      width: 324px;

      &.loading-mask {
        .fa-search {
          display: none;
          color: transparent;
        }

        .input-searchbox {
          background-color: #ddd;
          color: transparent;
          pointer-events: none;

          &::-webkit-input-placeholder {
            color: transparent;
          }

          &::-moz-placeholder {
            color: transparent;
          }

          &:-ms-input-placeholder {
            color: transparent;
          }

          &:-moz-placeholder {
            color: transparent;
          }
        }
      }
    }

    .filter-by-text {
      position: relative;
      width: 80px;
      color: #383838;
      font-family: Roboto;
      font-size: 14px;
      line-height: 1.71;
      text-align: center;
      margin-left: 5px;

      &:after {
        content: "";
        position: absolute;
        display: block;
        top: 25%;
        right: 0;
        height: 16px;
        border-right: 1px solid #e3e5e6;
        z-index: 1;
      }
    }

    .user-roles-filter {
      margin-left: 15px;

      &.loading-mask {
        background-color: #ddd;
        color: transparent;
        pointer-events: none;
        margin-left: 100px;

        .filter-typeahead-button {
          color: transparent;
        }

        .plus-icon {
          background-image: none;
        }
      }
    }

    .user-groups-filter {
      margin-left: 15px;

      &.loading-mask {
        background-color: #ddd;
        color: transparent;
        pointer-events: none;

        .filter-typeahead-button {
          color: transparent;
        }

        .plus-icon {
          background-image: none;
        }
      }
    }
  }

  .misc-actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    .c2-dropdown-options-wrapper {
      width: 124px;
      height: 32px;

      &.loading-mask {
        background-color: #ddd;
        color: transparent;
        pointer-events: none;

        .alt-anchor-wrapper {
          display: none;
        }
      }

      .c2-dropdown-options {
        align-items: center;
        width: 124px;
        height: 32px;

        .user-groups-button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 124px;
          height: 32px;
          background-color: transparent;
          color: #0b6d99;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 800;
          border: none;
          padding: 0 8px;
        }

        .c2-dropdown-menu-item {
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .add-user {
      margin-left: 20px;

      &.loading-mask {
        background-color: #ddd;
        color: transparent;
        pointer-events: none;
      }
    }
  }
}