.__react_component_tooltip {
  &.tooltip-wrapper {
    background-color: #2d2e2e;
    border-radius: 4px;
    box-shadow: 0 4px 32px 0 rgba(45, 46, 46, 0.16),
    0 0 4px 0 rgba(45, 46, 46, 0.2);
    color: #ffffff;
    font-size: 14px;
    line-height: 1.5;
    left: -15px;
    top: 7px;
    padding: 16px 20px;

    &.show {
      opacity: 1;
    }
  }
}
