.autoprovisioning {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header,
  .footer {
    flex: 0 0 auto;
  }

  .main-header {
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  }

  .contents {
    flex: 1 0 auto;
    padding: 24px 40px;

    .wrapper {
      max-width: 1000px;

      .add-groups-button {
        width: 125px;
        margin: 0;
      }

      .caption {
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.71;
        color: #737373;
        margin-bottom: 24px;
      }

      .no-group {
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.71;
        color: #737373;
        margin-top: 24px;
      }

      .section {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.71;
        color: #737373;
        margin-top: 24px;

        .arrow-icon {
          width: 20px;
          height: 20px;
          background-image: url("../../assets/imgs/icons.svg");
          background-position: -21px -660px;
          background-repeat: no-repeat;
          background-color: transparent;
          margin: 0 10px 0 10px;
        }
      }
    }
  }
}
