.add-autoprovisioning-modal {
  .row-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;

    .group-selectbox {
      width: 325px;

      &.no-error {
        height: 64px;
      }

      .input-textbox-label {
        color: #727273;
      }

      .input-select {
        .placeholder {
          background-color: transparent;
          cursor: pointer;
        }
      }

      .dropdown-wrapper {
        .dropdown {
          height: 200px;
          margin-bottom: 20px;
          overflow: auto;
        }
      }
    }

    .group-textbox {
      width: 325px;

      &.no-error {
        height: 64px;
      }

      .input-textbox-label {
        color: #727273;
      }
    }

    .arrow-icon {
      width: 20px;
      height: 20px;
      background-image: url("../../../assets/imgs/icons.svg");
      background-position: -21px -660px;
      background-repeat: no-repeat;
      background-color: transparent;
      margin: 20px 10px 0 10px;
    }

    .close-button {
      width: 18px;
      height: 18px;
      background-image: url("../../../assets/imgs/icons.svg");
      background-position: -225px -719px;
      background-repeat: no-repeat;
      margin-top: 20px;
      margin-left: 10px;
      float: right;
      cursor: pointer;

      &:hover {
        background-image: url("../../../assets/imgs/icons.svg");
        background-position: -225px -759px;
      }
    }
  }

  .end-row {
    .add-icon {
      width: 35px;
      height: 35px;
      background-image: url("../../../assets/imgs/icons.svg");
      background-position: -15px -451px;
      background-repeat: no-repeat;
      background-color: transparent;
      margin-bottom: 100px;

      &:not(.disabled):hover {
        background-position: -15px -508px;
        cursor: pointer;
      }

      &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }
}