.user-admin {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header,
  .footer {
    flex: 0 0 auto;
  }

  .header {
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  }

  .contents {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    padding: 40px;

    .selected-filters-container {
      .clear-button {
        margin-bottom: 22px;
        text-align: right;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}