@keyframes line-loader {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.text-banner {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 1200px;
  min-height: 92px;
  height: 92px;
  background-color: #fafafa;
  box-sizing: border-box;

  &:nth-child(2) {
    margin-top: 20px;

    .license-info:first-child {
      border-right: none;
    }
  }

  .license-info,
  .loading-indicator {
    background-color: transparent;
    padding: 24px 30px;
    border: none;
  }

  .license-info {
    &:not(:last-child) {
      border-right: 1px solid #e3e3e3;
    }
  }

  .license-info {
    &.bm {
      border-right: none;
      padding-right: 0;
    }

    .key {
      color: #7a7a7a;
      font-family: Roboto;
      font-size: 13px;
      line-height: 1.38;
      letter-spacing: 0.35px;
      text-transform: uppercase;
      margin-bottom: 6px;
      cursor: default;
    }

    .value {
      color: #293033;
      font-family: Roboto;
      font-size: 18px;
      line-height: 1.11;
      cursor: default;
    }
  }

  .loading-indicator {
    padding: 30px;

    .upper,
    .lower {
      height: 8px;
      background: linear-gradient(to right, #ebebeb 20%, #d9d9d9 50%, #ebebeb 80%);
      background-size: 200%;
      animation: line-loader 1.5s infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      border-radius: 3px;
    }

    .upper {
      width: 110px;
      margin-bottom: 15px;
    }

    .lower {
      width: 50px;
    }
  }
}