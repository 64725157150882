.edit-user-group-modal {
  .modal-content {
    height: 580px;

    .modal-body {
      .group-name-textbox {
        width: 380px;
        margin: 24px 24px 0 24px;
      }

      .typeahead-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        margin: 24px 24px 20px 24px;

        .select-users-typeahead {
          width: 380px;
        }

        .selected-user-caption {
          color: #7a7a7a;
          font-family: Roboto;
          font-size: 13px;
          font-style: italic;
          line-height: 1.85;
          margin-top: 25px;
          margin-left: 10px;
        }

        .info-icon {
          position: absolute;
          top: 0px;
          left: 80px;
          width: 18px;
          height: 18px;
          background-image: url("../../../../assets/imgs/icons.svg");
          background-position: -23px -246px;
          background-repeat: no-repeat;
        }

        .cybcube-tooltip {
          position: absolute;
          top: 10px;
          left: 110px;

          .cybcube-tooltip-arrow {
            border: none;
            border-right: 10px solid #2f2f2f;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            left: -10px;
            top: -6px;
          }

          .cybcube-tooltip-info {
            left: 0px;
            top: -25px;
            width: 425px;
          }
        }
      }

      .selected-users-list {
        width: 100%;
        height: calc(100% - 250px);
        margin-bottom: 24px;
        overflow-y: scroll;

        .list-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          height: 72px;
          padding: 10px 24px;

          &:nth-child(even) {
            background-color: #ffffff;
          }

          &:nth-child(odd) {
            background-color: #f7f7f7;
          }

          .top-row,
          .bottom-row {
            display: flex;
            align-items: center;

            .user-name {
              height: 24px;
              font-size: 14px;
              line-height: 1.71;
              color: #525252;
              margin-right: 12px;
            }

            .user-email {
              height: 24px;
              font-size: 13px;
              line-height: 1.85;
              color: #525252;
              margin-right: 10px;
            }

            .user-groups {
              max-width: 400px;
              height: 24px;
              font-size: 13px;
              line-height: 1.85;
              color: #525252;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .user-roles {
              max-width: 250px;
              height: 24px;
              font-size: 13px;
              line-height: 1.85;
              color: #525252;
              margin-right: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .divider {
              height: 10px;
              border-right: 1px solid #525252;
              margin: 0 8px;
            }

            .remove-button {
              width: 16px;
              height: 16px;
              background-image: url("../../../../assets/imgs/icons.svg");
              background-position: -226px -720px;

              &:hover {
                background-position: -226px -760px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .modal-footer {
      justify-content: space-between;

      button {
        margin: 0;
      }

      .cancel-button {
        width: 75px;
        margin-right: 16px;
      }

      .save-button {
        width: 64px;
      }
    }
  }
}

.confirm-modal {
  .modal-content {
    .modal-subheader {
      height: 56px;
      background-color: #f29b05;
      color: #293033;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
      padding: 16px 24px;
      cursor: default;

      .warning-icon {
        width: 24px;
        height: 24px;
        background-image: url("../../../../assets/imgs/icons.svg");
        background-position: -23px -751px;
        background-repeat: no-repeat;
        margin-right: 5px;
        float: left;
      }
    }

    .modal-body {
      .message {
        color: #525252;
        font-family: Roboto;
        font-size: 13px;
        cursor: default;
      }
    }

    .modal-footer {
      .primary {
        width: 118px;
      }

      .secondary {
        width: 75px;
        margin-right: 16px;
      }
    }
  }
}

.modal.fade .modal-medium.edit-user-group-modal {
  .modal-content .modal-body {
    padding: 0;
  }
}
