.error-status-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-align: center;
    color: #525252;
    
    .error-status-button {
        margin-top: 24px
    }

    .error-status-code {
        font-family: Roboto, sans-serif;
        font-size: 72px;
        font-weight: bold;
        margin-bottom: 18px;
    }
}
